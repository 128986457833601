import React, { useState, useContext, useEffect } from "react"
import { func } from "prop-types"
import { FirebaseContext } from "../components/Firebase"

const Register = () => {
  const { firebase } = useContext(FirebaseContext)

  const [errorMessage, setErrorMessage] = useState("")
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
  })

  function handleInputChange(e) {
    e.persist()
    setErrorMessage("")
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
  }
  let isMounted = true

  useEffect(() => {
    return () => {
      isMounted = false
    }
  }, [])

  function handleSubmit(e) {
    e.preventDefault()

    if (formValues.password === formValues.confirmPassword) {
      firebase
        .register({
          username: formValues.username,
          email: formValues.email,
          password: formValues.password,
        })
        .catch(error => {
          if (isMounted) {
            setErrorMessage(error.message)
          }
        })
    } else {
      setErrorMessage("Password and Confirm Password fields must match")
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <input
        onChange={handleInputChange}
        value={formValues.username}
        name="username"
        placeholder="username"
        type="text"
        required
      />
      <input
        onChange={handleInputChange}
        value={formValues.email}
        name="email"
        placeholder="email"
        type="email"
        required
      />
      <input
        onChange={handleInputChange}
        value={formValues.password}
        name="password"
        placeholder="password"
        type="password"
      />
      <input
        onChange={handleInputChange}
        value={formValues.confirmPassword}
        name="confirmPassword"
        placeholder="confirm"
        type="password"
      />
      {errorMessage && <span>{errorMessage}</span>}
      <button type="submit">Register</button>
    </form>
  )
}

export default Register
